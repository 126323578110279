.event-badge-holder{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.event-badge{
  padding: 20px;
  background: rgba(28, 27, 25, 0.3);
  border: 1px solid $harmonies;
  backdrop-filter: blur(20px);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: max-content;
  min-width: 350px;
  margin-top: 20px;
  
  @media screen and (max-width: 500px) {
    width: -webkit-fill-available;
    width: fill-available;
    min-width: auto;
    max-width: unset;
    margin-top: 0;
    border: none;
    flex-direction: row-reverse;
    padding: 0;
    backdrop-filter: none;
    background: transparent;
  }
}

.event-badge__img{
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 10px;
  position: relative;
  margin-right: 15px;
  overflow: hidden;

  @media screen and (max-width: 500px) {
    margin-left: 10px;
    margin-right: 0;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    border-radius: 8px;
  }
}

.event-badge__title{
  font-size: 18px;
  line-height: 21px;
  color: $primary-text-color;
  margin-bottom: 5px;

  @media screen and (max-width: 500px) {
    font-size: 14px;
    line-height: 17px;
  }
}

.event-badge__date{
  @media screen and (max-width: 500px) {
    font-size: 12px;
    line-height: 14px;
    margin-top: 4px;
  }
}